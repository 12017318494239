<template>
    <div class="stage-inner-container-padded">
        <b-overlay :show="isSubmitting" rounded="sm">
            <template v-slot:overlay>
                <div class="text-center">
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                    <p>The Carrier Pidgeons Have Been Dispatched</p>
                </div>
            </template>
            <div v-if="!submissionCompleted">
                <b-row>
                    <b-col cols="12" md="6">
                        <h2>Create A New Site</h2>
                    </b-col>
                    <b-col cols="12">
                        <hr>
                    </b-col>
                    <b-col cols="12">
                        <!-- Main Content -->
                        <b-form v-on:submit.prevent>
                            <b-card v-if="requireSelectParent" header="Select Parent" header-class="larger-header">
                                <parent-api-select
                                    dataName="customer_id"
                                    inputLabel="Customer"
                                    :parentFormID="localFormID"
                                    :required="true"
                                    :parentType="'customers'"
                                    :value="null"
                                    :change="parentSelected"
                                ></parent-api-select>
                            </b-card>
                            <b-row class="mt-2">
                                <!-- Spacer -->
                            </b-row>
                            <b-card v-if="parentInfoFetched" :header="'Parent Selected: ' + this.ownParentID" header-class="larger-header">
                                <b-card-body>
                                    <!-- Parent Info Block -->
                                    <b-row no-gutters>
                                        <b-col sm="3" md="3" lg="2" xl="2">
                                            <!-- Empty Spacer -->
                                            <b-button :href="linkToParent" target="_blank" variant="primary">View Customer In New Tab</b-button>
                                        </b-col>
                                        <b-col sm="8" md="8" lg="9" xl="9">
                                            <b-row no-gutters>
                                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                                    <b>Customer Name:</b> {{parent.customer_name}} 
                                                </b-col>
                                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                                    <b>Phone Number(s):</b> {{parent.phone_number}} 
                                                </b-col>
                                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                                    <b>Email(s):</b> {{parent.email}} 
                                                </b-col>
                                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                                    <b>Notes:</b> {{parent.notes}} 
                                                </b-col>
                                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                                    <b>Address:</b> {{parent.formatted_address}} 
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-card>
                            <b-row class="mt-2">
                                <!-- Spacer -->
                            </b-row>
                            <b-card header="Site Information" header-class="larger-header">
                                <b-button v-if="this.parentInfoFetched" @click="copyFromParent(false, true)" class="mr-2">Copy Contact Info From Customer</b-button>
                                <b-button v-if="this.parentInfoFetched" @click="copyFromParent(true, true)">Copy Contact Info And Address From Customer</b-button>
                                <hr>
                                <text-input
                                    dataName="site_name"
                                    inputLabel="Site Name"
                                    :parentFormID="localFormID"
                                ></text-input>
                                <api-select
                                    :parentFormID="localFormID"
                                    dataName="site_type"
                                    inputLabel="Site Type"
                                    :required="true"
                                    :apiEndpoint="'/choices/site_types.json?choices=true'"
                                ></api-select>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <text-input v-if="!copyingFromParent"
                                            dataName="site_contact_first_name"
                                            inputLabel="Site Contact First Name"
                                            :parentFormID="localFormID"
                                            :value="siteContactDefault.first"
                                        ></text-input>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <text-input v-if="!copyingFromParent"
                                            dataName="site_contact_last_name"
                                            inputLabel="Site Contact Last Name"
                                            :parentFormID="localFormID"
                                            :value="siteContactDefault.last"
                                        ></text-input>
                                    </b-col>
                                </b-row>
                                <array-input v-if="!copyingFromParent"
                                    :parentFormID="localFormID"
                                    dataName="site_contact_phone_number"
                                    inputLabel="Site Contact Phone Number"
                                    componentName="phone-input"
                                    :value="siteContactDefault.phone"
                                ></array-input>
                                <array-input v-if="!copyingFromParent"
                                    :parentFormID="localFormID"
                                    dataName="site_contact_email"
                                    inputLabel="Site Contact Email"
                                    componentName="email-input"
                                    :value="siteContactDefault.email"
                                ></array-input>
                                <b-button v-if="this.parentInfoFetched" @click="copyFromParent(true, false)">Copy Address From Customer</b-button>
                                <address-input v-if="!copyingFromParent"
                                    :parentFormID="localFormID"
                                    dataName="service_address"
                                    inputLabel="Service Address"
                                    :value="siteAddressDefault"
                                    :isSite="true"
                                ></address-input>
                            </b-card>
                            <b-row class="mt-2">
                                <!-- Spacer -->
                            </b-row>
                            <b-button-toolbar justify>
                                <b-button variant="warning" @click="$router.go(-1)">Discard New Site and Exit</b-button>
                                <b-button variant="success" @click="attemptSend">Create Site</b-button>
                            </b-button-toolbar>
                        </b-form>
                    </b-col>
                </b-row>
            </div>
            <div v-else>
                <b-row align-h="center">
                    <b-col cols="12" md="6" align-self="center">
                        <div class="d-flex justify-content-center">
                            <h3>Added Site</h3>
                        </div>
                        <b-button-toolbar justify>
                            <b-button variant="warning" @click="$router.go(-1)">Back</b-button>
                            <b-button variant="success" @click="goToNewlyCreated">View New Site</b-button>
                        </b-button-toolbar>
                    </b-col>
                </b-row>
            </div>
        </b-overlay>
    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
const _ = require('underscore');

// Form Components
import RegexText from '@/components/FormTextRegexInput.vue'
import TextArea from '@/components/FormTextAreaInput.vue'
import PassedSelect from '@/components/FormSelectPassedValuesInput.vue'
import APISelect from '@/components/FormSelectFetchAPIInput.vue'
import Decimal2x2Input from '@/components/FormDecimalTwoByTwoInput.vue'
import DecimalInput from '@/components/FormDecimalInput.vue'
import IntegerInput from '@/components/FormIntegerInput.vue'
import GPSInput from '@/components/FormGPSInput.vue'
import PhoneInput from '@/components/FormPhoneInput.vue'
import EmailInput from '@/components/FormEmailInput.vue'
import FormArrayInput from '@/components/FormArrayEngine.vue'
import RadioInput from '@/components/FormRadioInput.vue'
import BoolInput from '@/components/FormBooleanInput.vue'
import AddressInput from '@/components/FormAddressComponent.vue'
import NestedForm from '@/components/NestedChildrenForms.vue'
import EventList from '@/components/FormEventListHistory.vue'
import ParentSelect from '@/components/FormParentSelection.vue'
import IssueFulcrum from '@/components/subcomponents/IssueToFulcrumModal.vue'

export default {
    name: 'createsite',
    components: {
        'text-input': RegexText,
        'decimal-2x2-input': Decimal2x2Input,
        'text-area': TextArea,
        'passed-select': PassedSelect,
        'api-select': APISelect,
        'decimal-input': DecimalInput,
        'integer-input': IntegerInput,
        'gps-input': GPSInput,
        'phone-input': PhoneInput,
        'email-input': EmailInput,
        'array-input': FormArrayInput,
        'radio-select': RadioInput,
        'bool-select': BoolInput,
        'address-input': AddressInput,
        'nested-child-forms': NestedForm,
        'event-list': EventList,
        'issue-to-fulcrum-modal': IssueFulcrum,
        'parent-api-select': ParentSelect
    },
    props:{
        localFormID: {
            type: String,
            default: () =>{ return uuidv4(); }
        },
        parentID: {
            type: String,
            default: () =>{ return null; }
        }
    },
    data(){
        return{
            isSubmitting: false,
            submissionCompleted: false,
            newSiteID: null,
            siteAddressDefault: {
                dataNamePrefix: "service_address",
                number: null,
                predirectional: null,
                name: null,
                suffix: null,
                postdirectional: null,
                unit: null,
                building: null,
                extra: null,
                city: null,
                state: null,
                zip: null,
                zipPlusFour: null,
                zipPlusTwo: null,
                lineTwo: null
            },
            siteContactDefault: {
                first: null,
                last: null,
                phone: [],
                email: []
            },
            siteContactFirst: null,
            siteContactLast: null,
            requireSelectParent: true,
            type: "sites",
            parentType: "customers",
            parentInfoFetched: false,
            ownParentID: null,
            parent: {},
            copyingFromParent: false
        }
    },
    methods: {
        navigateTo(path){
            this.$router.push({path: path})
        },
        attemptSend(){
            // Fetch Form From Vue
            var form = this.$store.getters.getForm(this.localFormID);
            // Get Form Validity
             var x = this.checkFormForValiditiy(form);
            if(x.valid){
                // Orient Form So Its Ready To Send To The API
                var sendable = {};
                // Remove Meta From Consideration
                var valid = _.omit(form, 'meta');
                // Get Keys
                var keys = _.keys(valid);
                
                keys.forEach((key)=>{
                    if(valid[key].type == "formarrayengine"){
                        var arrayVal = [];
                        var children = _.keys(valid[key].children);
                        children.forEach((childKey, index)=>{
                            arrayVal.push(valid[key].children[childKey].value);
                        })
                        sendable[key] = arrayVal;
                    }else{
                        if(valid[key].value != undefined){
                            sendable[key] = valid[key].value
                        }
                    }
                })
                console.log(sendable);
                this.isSubmitting = true;
                instance.post(process.env.VUE_APP_API_BASE_URL + '/sites.json', sendable)
                    .then(async (response) => {
                        this.newSiteID = response.data.result.site_id;
                        this.isSubmitting = false;
                        this.submissionCompleted = true;
                    })
                    .catch((error) => {
                        this.isSubmitting = false;
                        console.log(error);
                        console.log(error.response)
                        this.makeToast("Failed To Save Site", `Something Went Wrong Durring The Request`, "danger", 30000);
                    });
            }else{
                // Complain About This To The User
                console.log(x);
                var failKeys = _.keys(x.failures);
                failKeys.forEach((failure)=>{
                    var val = this.transformToProperCase(failure);
                    this.makeToast("Cannot Save Form - Form Invalid", `${val} is invalid`, "danger", 5000);
                })
            }
        },
        checkFormForValiditiy(form){
            // Remove Meta From Consideration
            var valid = _.omit(form, 'meta');
            // Get Keys
            var keys = _.keys(valid);
            // Check Each Key
            var allValidations = {};
            keys.forEach((key)=>{
                if(valid[key].type == "formarrayengine"){
                    var children = _.keys(valid[key].children);
                    children.forEach((childKey, index)=>{
                        allValidations[key + '-_-' + index] = valid[key].children[childKey].valid;
                    })
                }else{
                    allValidations[key] = valid[key].valid;
                }
            });
            // Omit Passing Keys
            var failures = _.omit(allValidations, (val, key, obj) => {
                return !!(val);
            });
            var valid;
            if(_.keys(failures).length > 0){
                valid = false;
            }else{
                valid = true;
            }
            return {
                valid,
                failures
            };
        },
        makeToast(toastTitle, toastBody = '', toastVariant = null, delay = 3000){
            this.$bvToast.toast(toastBody, {
                variant: toastVariant,
                toaster: 'b-toaster-bottom-center',
                title: toastTitle,
                autoHideDelay: 3000,
                appendToast: true
            })
        },
        transformToProperCase(val){
            var tmp = val.replace(/_/g, " ");
            return this.capitalizeEachWord(tmp);
        },
        capitalizeEachWord(str) {
            return str.replace(/\w\S*/g, function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        },
        goToNewlyCreated(){
            this.$router.push({path: this.getLinkToForm(this.type, this.newSiteID)})
        },
        getLinkToForm(formType, inspectID){
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: formType});
            if(defaultFormForType == null){
                this.makeToast("No Form Available To Handle This Request", `Setup a form for ${formType}. \nIf you think this is an error, logout and log back in.`)
            }else if(defaultFormForType.length == 0){
                this.makeToast("No Form Available To Handle This Request", `Setup a form for ${formType}. \nIf you think this is an error, logout and log back in.`)
            }else{
                return '/home/form/' + defaultFormForType[0].id + '/' + inspectID;
            }
        },
        parentSelected(pid){
            console.log("Parent ID Selected")
            console.log(pid);
            this.fetchParent(pid);
        },
        fetchParent(pid){
            var parentIDName = 'customer_id';
            instance.get(process.env.VUE_APP_API_BASE_URL + '/' + this.parentType + '/' + pid + '.json?dref=true')
                .then(async (response) => {
                    if(response.data.result.records.length == 1){
                        var cst = response.data.result.records[0];
                        this.parentInfoFetched = true;
                        this.parent.formatted_address = cst.formatted_address;
                        this.parent.customer_name = cst.customer_name;
                        this.parent.phone_number = cst.phone_number;
                        this.parent.notes = cst.notes;
                        this.parent.email = cst.email;

                        this.ownParentID = cst.customer_id;
                        this.$store.commit('registerFormValue', {parentFormID: this.localFormID, type: 'formselectparent', dataName: parentIDName, index: -1, value: this.ownParentID, valid: true} );

                    }else{
                        this.makeToast("Failed To Fetch Customer Info", "Customer Info Could Not Be Retrieved Or Was Not Retrieved, Check The Link or Selection then Attempt Again", 'danger');
                        this.$store.commit('registerFormValue', {parentFormID: this.localFormID, type: 'formselectparent', dataName: parentIDName, index: -1, value: pid, valid: false} );
                    }
                })
                .catch((error) => {
                    this.makeToast("Failed To Fetch Customer Info", "Customer Info Could Not Be Retrieved Or Was Not Retrieved, Check The Link or Selection then Attempt Again", 'danger');
                    console.log(error);
                    this.$store.commit('registerFormValue', {parentFormID: this.localFormID, type: 'formselectparent', dataName: parentIDName, index: -1, value: pid, valid: false} );
                });
        },
        copyAddressFromParent(){
            if(this.ownParentID != null){
                this.copyingFromParent = true;
                instance.get(process.env.VUE_APP_API_BASE_URL + '/' + this.parentType + '/' + this.ownParentID + '.json')
                .then(async (response) => {
                    if(response.data.result.records.length == 1){
                        var cst = response.data.result.records[0];
                        var customerAddressData = {
                            dataNamePrefix: "service_address",
                            number: cst.billing_address_number,
                            predirectional: cst.billing_address_predirection,
                            name: cst.billing_address_street_name,
                            suffix: cst.billing_address_street_suffix,
                            postdirectional: cst.billing_address_postdirectional,
                            unit: cst.billing_address_unit,
                            building: cst.billing_address_building,
                            extra: cst.billing_address_extra,
                            city: cst.billing_address_city,
                            state: cst.billing_address_state,
                            zip: cst.billing_address_zip_code,
                            zipPlusFour: cst.billing_address_zip_code_plus_four,
                            zipPlusTwo: cst.billing_address_zip_code_plus_two,
                            lineTwo: cst.billing_address_line_two
                        };
                        this.siteAddressDefault = customerAddressData;
                        this.copyingFromParent = false;

                    }else{
                        this.makeToast("Failed To Fetch Customer Address", "Customer address could not be copied.", 'danger');
                    }
                })
                .catch((error) => {
                    this.makeToast("Failed To Fetch Customer Address", "Customer address could not be copied.", 'danger');
                    console.log(error);
                });
            }
        },
        copyContactInfoFromParent(){
            if(this.ownParentID != null){
                this.copyingFromParent = true;
                instance.get(process.env.VUE_APP_API_BASE_URL + '/' + this.parentType + '/' + this.ownParentID + '.json')
                .then(async (response) => {
                    if(response.data.result.records.length == 1){
                        var cst = response.data.result.records[0];
                        console.log(cst);
                        var custContactInfo = {
                            first: cst.first_name,
                            last: cst.last_name,
                            phone: cst.phone_number,
                            email: cst.email
                        };
                        this.siteContactDefault = custContactInfo;
                        this.copyingFromParent = false;

                    }else{
                        this.makeToast("Failed To Fetch Customer Info", "Customer data could not be copied.", 'danger');
                    }
                })
                .catch((error) => {
                    this.makeToast("Failed To Fetch Customer Info", "Customer data could not be copied.", 'danger');
                    console.log(error);
                });
            }
        },
        copyFromParent(copyAddress, copyContactInfo){
            if(this.ownParentID != null){
                this.copyingFromParent = true;
                instance.get(process.env.VUE_APP_API_BASE_URL + '/' + this.parentType + '/' + this.ownParentID + '.json')
                .then(async (response) => {
                    if(response.data.result.records.length == 1){
                        var cst = response.data.result.records[0];
                        if(copyContactInfo){
                            var custContactInfo = {
                                first: cst.first_name,
                                last: cst.last_name,
                                phone: cst.phone_number,
                                email: cst.email
                            };
                            this.siteContactDefault = custContactInfo;
                        }

                        if(copyAddress){
                            var customerAddressData = {
                                dataNamePrefix: "service_address",
                                number: cst.billing_address_number,
                                predirectional: cst.billing_address_predirection,
                                name: cst.billing_address_street_name,
                                suffix: cst.billing_address_street_suffix,
                                postdirectional: cst.billing_address_postdirectional,
                                unit: cst.billing_address_unit,
                                building: cst.billing_address_building,
                                extra: cst.billing_address_extra,
                                city: cst.billing_address_city,
                                state: cst.billing_address_state,
                                zip: cst.billing_address_zip_code,
                                zipPlusFour: cst.billing_address_zip_code_plus_four,
                                zipPlusTwo: cst.billing_address_zip_code_plus_two,
                                lineTwo: cst.billing_address_line_two
                            };
                            this.siteAddressDefault = customerAddressData;
                        }
                        
                        this.copyingFromParent = false;

                    }else{
                        this.makeToast("Failed To Fetch Customer", "Customer data could not be copied.", 'danger');
                    }
                })
                .catch((error) => {
                    this.makeToast("Failed To Fetch Customer", "Customer data could not be copied.", 'danger');
                    console.log(error);
                });
            }
        }
    },
    computed: {
        linkToParent(){
            return this.getLinkToForm(this.parentType, this.ownParentID);
        },
    },
    mounted(){
        if(this.$route.params.parent_id == undefined){
            if(this.parentID == null){
                console.log("parent_id is undefined")
                this.requireSelectParent = true;
            }else{
                console.log("Parent ID Passed By Prop")
                this.ownParentID = this.parentID;
                this.requireSelectParent = false;
                this.parentSelected(this.ownParentID)
            }
            
        }else{
            console.log(this.$route.params.parent_id)
            this.ownParentID = this.$route.params.parent_id;
            this.requireSelectParent = false;
            this.parentSelected(this.ownParentID)
        }
    },
    created(){
    },
    beforeDestroy(){
        this.$store.commit('clearForm', {localFormID: this.localFormID});
    },
    beforeMount (){
        // Create A Form In Vuex For This New Record
        this.$store.commit('initForm',{ localFormID: this.localFormID, meta: {} });
    },
}
</script>

<style scoped>
</style>

